@import "../../../assets/css/variables";

.facility-filter {
  display: flex;
  margin-bottom: 34px;
  span {
    color: $white;
    background-color: $blue-color;
    padding: 18px;
    display: flex;
    align-items: center;
    font-weight: 700;
  }
  .filter-btns {
    padding: 10px 15px;
    background-color: $dark-gray;
    min-height: 60px;
    width: 100%;
    display: flex;
    gap: 13px;
    flex-wrap: wrap;
    .btn-secondary {
      &.white-btn {
        background-color: $white;
        color: $blue-color;
        //margin-right: 13px;
        font-size: 16px;
        font-weight: 500;
        padding: 7px 16px;
        border-radius: 5px;
        &:hover {
          background-color: $secondary;
          color: $white;
        }
        border: 0;
        &.min-162 {
          min-width: 162px;
        }
        &:last-child {
          margin-right: 0;
        }
        @include md {
          font-size: 14px;
          padding: 7px 12px;
          &.min-162 {
            min-width: 85px;
            @include sm {
              min-width: initial;
            }
          }
        }
      }
    }
  }
  @include xl {
    margin-top: 20px;
    flex-wrap: wrap;

    span {
      width: 100%;
    }
  }
}

.facility-list {
  .facility-box {
    display: flex;
    margin-bottom: 20px !important;

    .facility-img {
      // min-width: 340px;
      max-width: 300px;
      //max-height: 300px;
      .shimmer {
        max-width: 300px;
        height: 100% !important;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @include xxxl {
        min-width: 250px;
      }
      @include xxl {
        min-width: 220px;
      }
      @include xl {
        min-width: 300px;
      }
      @include md {
        min-width: 100%;
      }
    }
    .facility-info {
      width: -webkit-fill-available;
      padding: 30px 16px 10px 50px;
      background-color: $blue-color;
      color: $white;
      h3 {
        font-size: 16px;
        font-weight: 700;
        font-family: $font-family;
      }
      p,
      span {
        font-size: 12px !important;
        color: $white !important;
      }
      ul {
        li {
          list-style: inside;
          font-size: 12px;
          color: $white;
        }
      }
      .fac-list-price {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        ul {
          li {
            font-size: 12px;
            list-style: inside;
            margin-bottom: 6px;
          }
        }
        .facility-price {
          div {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 5px;
            span {
              font-size: 24px !important;
              font-weight: 700;
              color: $secondary-text1 !important;
              margin-right: 11px;
            }
            p {
              margin-bottom: 0;
              color: $light-yellow !important;
            }
          }
          .btn-primary {
            min-width: 204px;
          }
        }
        @include md {
          flex-wrap: wrap;
          > div {
            width: 100%;
          }
        }
      }
      @include md {
        padding: 20px 15px;
      }
    }
    @include md {
      flex-wrap: wrap;
      width: calc(50% - 7px);
      margin-right: 7px;
      display: inline-block;
      &:nth-child(2n) {
        margin-right: 0;
        margin-left: 7px;
      }
    }
    @include sm {
      width: 100%;
      margin: 0 !important;
      margin-bottom: 20px !important;
    }
  }
  @include xl {
    margin-top: 20px;
  }
}
