@import './variables';

html,
body {
  margin: 0;
  padding: 0 !important;
  font-family: $font-family;
  overflow-y: auto;
  scroll-behavior: initial;
}
:root {
  scroll-behavior: initial;
}
ul,
ol {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none !important;
  @include transition5s();

  &:hover {
    color: $primary !important;
  }
}

button {
  @include transition5s();

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font;
}

h1,
.h1 {
  font-size: $ec-h1;

  @include sm {
    font-size: $ec-h2;
  }
}

h2,
.h2 {
  font-size: $ec-h2;
}

h3,
.h3 {
  font-size: $ec-h3;
}

h4,
.h4 {
  font-size: $ec-h4;
}

h5,
.h5 {
  font-size: $ec-h5;
}

h6,
.h6 {
  font-size: $ec-h6;
}

// .flatpickr-input {
//   // &::-webkit-inner-spin-button,
//   // &::-webkit-calendar-picker-indicator{
//   //   display: none;
//   //   -webkit-appearance: none;
//   // }
// }

//commom
.max-1920 {
  max-width: 1950px;
  margin: auto;
  padding: 0px 15px;
  &.p-lg-0 {
    @include lg {
      padding: 0;
    }
  }
}

.bg-gray {
  background-color: $dark-gray !important;

  &.sticky {
    top: 130px;

    @include lg {
      position: static;
    }
  }
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 9;
}

html .pb-150 {
  padding-bottom: 150px;
}

.circle {
  height: 56px;
  width: 56px;
  border-radius: 50%;
  font-size: 0;
}

.line {
  align-items: center;
  color: #f6f7f800;
}

.shine {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  position: relative;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

div#root {
  position: relative;
}

//buttons
.btn-primary {
  background-color: $secondary !important;
  border-color: $secondary !important;
  color: $blue-color !important;
  box-shadow: none !important;
  font-size: 16px;
  font-weight: 500;

  &:hover,
  &:focus {
    background-color: $secondary-text !important;
    border-color: $secondary-text !important;
    color: $blue-color !important;
  }

  @include sm {
    font-size: 14px;

    svg {
      //max-width: 16px;
    }
  }
}

.btns-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &.search-btn {
    margin-top: 18px;

    .btn-primary {
      height: 40px;
      padding: 6px 35px;
      line-height: normal;
      font-weight: 500;

      &.btn-lg {
        height: 68px;
        padding: 16px 89px;
        font-size: 22px;
      }

      @include sm {
        width: 100%;
        margin-bottom: 8px;
        height: 40px !important;
        padding: 6px 35px !important;
        font-size: 1rem !important;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

//buttons end
//objetc-position
.object-position-top {
  object-position: $top !important;
}

.object-position-bottom {
  object-position: $bottom !important;
}

.object-position-left {
  object-position: $left !important;
}

.object-position-right {
  object-position: $right !important;
}

//Home
.main-div {
  min-height: calc(120vh - 370px);
  position: relative;
  //max-height: 100vh;
  padding-top: 100px;
  //padding-bottom: 143px;
  &.home-main {
    min-height: unset;
    .slick-slide img {
      min-height: 937px;
      //@at-root: cover;
      //width: auto !important;
      //min-height: 1080px;
      object-fit: cover;
      height: 1200px;
      @media (max-width: 1200px) {
        height: 1000px;
      }
      @media (max-width: 992px) {
        height: 700px;
        min-height: unset;
      }
    }
  }
  .title {
    text-align: center;
    margin-bottom: 20px;

    h1 {
      color: $white;
      line-height: normal;
      margin-bottom: 18px;

      @include lg {
        font-size: 75px;
      }

      @include md {
        font-size: 48px;
      }

      @include sm {
        font-size: 34px;
      }
    }

    h2 {
      color: $secondary-text;
      line-height: normal;
      margin-bottom: 0;

      @include lg {
        font-size: 26px;
      }

      @include md {
        font-size: 22px;
      }

      @include sm {
        font-size: 18px;
      }
    }
  }

  @include lg {
    padding: 0;
    max-height: initial;
  }

  // &.home-main{
  //   height: 100vh;
  //   .slick-slide img{
  //     height: calc(100vh - 242px);
  //     width: 100%;
  //     object-fit: cover;
  //     @media (max-width:1080px) and (min-width:992px){
  //       height: calc(100vh - 255px);
  //     }
  //     @include lg{
  //       height: auto;
  //     }
  //   }
  //   @include lg{
  //     height: auto;
  //   }
  // }
}

//Home End

//About
.bg-banner {
  padding-top: 130px;
  // background-image: url(../images/about-banner.jpg);
  // background-repeat: no-repeat;
  // background-size: cover;
  height: 100vh;
  padding-bottom: 145px;
  overflow: auto;

  &.about,
  &.activity {
    min-height: 1080px;
    background-position: bottom;
    .search-block {
      position: absolute;
      height: max-content;
      margin: auto;
      @include lg {
        margin: auto !important;
      }
    }
    .title {
      margin-bottom: 29px;

      h1 {
        font-size: 60px;
        margin-bottom: 5px;

        @include lg {
          font-size: 48px;
        }

        @include md {
          font-size: 34px;
        }

        @include sm {
          font-size: 30px;
        }
      }

      h2 {
        font-size: 24px;

        @include lg {
          font-size: 22px;
        }

        @include md {
          font-size: 18px;
        }
      }
    }

    .search-fields {
      span {
        color: $secondary-text;
        font-size: 16px;
        font-weight: 700;
        display: block;

        &:last-child {
          margin-top: 22px;
        }
      }

      p {
        font-size: 12px;
        font-weight: 400;
        color: $white;
        margin-bottom: 15px !important;

        @include sm {
          font-size: 11px;
          margin-bottom: 8px !important;
        }
      }
    }

    &.activity {
      // background-image: url(../images/activity-banner.jpg);
      .search-fields {
        p {
          margin-bottom: 0 !important;
        }
        a {
          color: $secondary;
          font-size: 12px;
          margin-bottom: 15px;
          display: inline-block;
          font-weight: 500;
          &:hover {
            color: $secondary-text !important;
          }
        }
      }
      @include lg {
        height: calc(100vh - 90px);
        min-height: inherit;
      }
    }

    @include lg {
      height: auto;
      min-height: 1200px;
      .search-block {
        margin: 120px 0;
      }
    }

    @include sm {
      padding: 25px 0px;
      max-height: initial;
    }
  }

  img {
    width: 100%;
    //height: 100vh;
    object-fit: cover;
    object-position: center;

    @include lg {
      height: 820px;
    }

    @include sm {
      height: 750px;
    }
  }
}

//About end

//gallery
.bg-banner2 {
  position: relative;

  .sub-main-div {
    max-width: 1920px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  img {
    height: 270px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .second-title {
    max-width: 772px;
    max-height: 187px;
    height: 100%;
    width: 100%;
    left: 0;
    bottom: 0;
    text-align: center;
    position: absolute;
    padding: 34px 15px 15px;
    background-color: rgba($blue-color, 0.8);
    border-radius: 0px 8px 0px 0px;

    @include md {
      border-radius: 0;
    }

    h1 {
      font-size: 60px;
      font-weight: 400;
      color: $white;

      @include lg {
        font-size: 48px;
      }

      @include md {
        font-size: 34px;
      }

      @include sm {
        font-size: 30px;
      }
    }

    h2 {
      font-size: 24px;
      font-weight: 400;
      color: $secondary-text;

      @include lg {
        font-size: 22px;
      }

      @include md {
        font-size: 18px;
      }
    }

    @include md {
      max-height: 140px;
    }
  }
}

//gallery end

//facility
.facility-main {
  padding: 51px 13px 160px;
  max-width: 1950px;
  margin: auto;

  @include xl {
    padding-top: 0;
    .col-xl-5 {
      padding: 0;
    }
  }

  @include lg {
    padding-bottom: 60px;
  }

  div#toggler {
    position: relative;

    .cart-title {
      border-radius: 0;
      justify-content: center;
      text-transform: capitalize;
      font-weight: normal;
      font-size: 18px !important;
      padding: 6px 23px;
    }

    .btn-primary {
      position: absolute;
      right: 24px;
      top: 0;
      bottom: 0;
      height: 30px;
      margin: auto;
      width: 30px;
      padding: 0;
    }
  }
}

//facility end

// download start
.banner {
  position: relative;
}

.download-banner {
  //height: 100vh;
  padding-top: 130px;
  // background-image: url(../images/slider3.jpg);
  // //padding-bottom: 143px;
  // background-repeat: no-repeat;
  // background-size: cover;
  height: 100vh;
  padding-bottom: 145px;
  overflow: auto;
  min-height: 1080px;
  .document-menu {
    position: absolute;
    margin: auto;
  }

  &.direction-bg {
    background-image: url(../images/direction-banner.jpg);
  }

  img {
    width: 100%;
    //height: 100vh;
    object-fit: cover;
    object-position: center;

    @include lg {
      height: 820px;
    }

    @include sm {
      height: 680px;
    }
  }

  @include lg {
    height: initial;
    padding: 0;
  }
}

.document-menu {
  // position: absolute;
  top: 0;
  bottom: 0;
  margin: 40px 0 20px;
  width: 100%;
  max-width: 772px;
  max-height: 636px;
  border-radius: 0 $ec-radius $ec-radius 0;
  background-color: rgba($blue-color, 0.8);
  padding-bottom: 20px;

  @include lg {
    margin: 120px 0;
  }

  @include md {
    width: 100%;
    height: 100%;
  }

  h1 {
    text-transform: capitalize;
    text-align: center;
    margin: 0 auto;
    color: $white;
    font-size: 60px;
    line-height: 70px;
    font-weight: 400;
    padding: 25px 0 15px 0;

    @include sm {
      font-size: 40px;
      line-height: 60px;
      padding-bottom: 10px;
    }
  }

  h3 {
    color: $secondary-text;
    text-align: center;
    font-size: 24px;
    line-height: 35px;
    font-weight: 400;

    @include sm {
      font-size: 20px;
      line-height: 25px;
      padding: 0 10px;
    }
  }

  .document-link {
    color: $white;
    margin: 20px 40px 250px 40px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;

    p {
      a {
        text-decoration: underline !important;

        &:hover {
          color: $white !important;
        }
      }
    }

    .all-links {
      a {
        text-transform: capitalize;
        display: block;
        text-decoration: underline !important;
        color: $white;
        line-height: 25px;
        padding: 10px 0;

        &:hover {
          color: rgba($white, 0.7) !important;
        }

        @include sm {
          padding: 5px 0;
        }
      }
    }
  }
}

// download end

//thank you start
.main-div {
  .banner-img {
    position: relative;

    img {
      height: 270px;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .sub-div {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;

    .thankyou-banner {
      max-width: 700px;
      max-height: 187px;
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      text-align: center;
      background-color: rgba($blue-color, 0.8);
      padding: 34px 0 15px 0;
      border-radius: 0 $ec-radius1 0 0;

      @include md {
        width: 100%;
        border-radius: 0;
        max-height: 140px;
        padding: 25px 0 10px 0;
      }

      h1 {
        color: $white;
        font-size: 60px;
        line-height: 75px;
        font-weight: 400;

        @include lg {
          font-size: 48px;
          line-height: 60px;
        }

        @include md {
          font-size: 34px;
          line-height: 45px;
        }

        @include sm {
          font-size: 30px;
          line-height: 40px;
        }
      }

      h2 {
        color: $secondary-text;
        font-size: 24px;
        font-weight: 400;
        line-height: 35px;

        @include lg {
          font-size: 22px;
        }

        @include md {
          font-size: 18px;
        }
      }
    }
  }

  .booking-success {
    text-align: center;
    height: 100%;
    margin: 154px 0 118px 0;

    @include sm {
      margin: 100px 0;
    }

    .title {
      text-transform: uppercase;
      font-size: 45px;
      font-weight: 700;
      color: $blue-color;

      @include md {
        font-size: 34px;
        margin-bottom: 30px;
      }

      @include sm {
        font-size: 26px;
      }
    }

    .booking-id {
      :first-child {
        font-size: 22px;
        font-weight: 700;
        color: $blue-color;
        margin-bottom: 0;
        text-transform: uppercase;
        border-radius: $ec-radius1;

        @include md {
          font-size: 20px;
        }

        @include sm {
          font-size: 18px;
        }
      }

      :last-child {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $blue-color;
        border-radius: $ec-radius1;

        @include sm {
          font-size: 16px;
        }
      }
    }

    .myaccount-btn {
      margin-right: 20px;
    }

    .button-back {
      width: 200px;
      height: 40px;
      font-size: 16px;
      font-weight: 500;
      margin-top: 50px;
      border-radius: $ec-radius;

      &::first-letter {
        text-transform: capitalize;
      }

      @include md {
        margin-top: 30px;
      }

      @include sm {
        width: 129px;
      }
    }

    > .container {
      padding-bottom: 250px;

      @include lg {
        padding-bottom: 0;
      }
    }
  }
  &.home-main {
    padding-top: 0;
  }
}

// thank you end

//contact start

.contact-main {
  padding-bottom: 150px;

  .contact-img {
    position: relative;

    img {
      width: 100%;
      height: 270px;
      object-fit: cover;
      object-position: center;
    }

    .sub-div {
      position: absolute;
      max-width: 1920px;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;

      .contact-banner {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: rgba($blue-color, 0.8);
        text-align: center;
        max-width: 700px;
        max-height: 187px;
        height: 100%;
        width: 100%;
        padding: 34px 0 15px 0;
        border-radius: 0 $ec-radius1 0 0;

        @include md {
          max-height: 140px;
        }

        h1 {
          font-size: 60px;
          font-weight: 400;
          text-transform: capitalize;
          color: $white;

          @include lg {
            font-size: 48px;
            line-height: 60px;
          }

          @include md {
            font-size: 34px;
            line-height: 45px;
          }

          @include sm {
            font-size: 30px;
            line-height: 40px;
          }
        }

        h2 {
          font-size: 24px;
          font-weight: 400;
          line-height: 35px;
          color: $secondary-text;

          @include lg {
            font-size: 22px;
            line-height: 30px;
          }

          @include md {
            font-size: 18px;
            line-height: 25px;
          }
        }
      }
    }
  }

  .contact-info-form {
    max-width: 1950px;
    padding: 60px 15px;
    margin: auto;
    display: flex;

    .form {
      margin: 0px 40px 0;
      max-width: 660px;
      display: inline-block;
      width: 100%;

      p {
        font-size: 12px;
        font-weight: 400;
        color: $blue-color;
        margin-top: 17px;
        max-width: initial;
      }

      .write-us {
        background-color: $dark-gray;
        border: 1px solid transparent;
        padding: 20px 0 20px 23px;
        border-radius: $ec-radius;
        color: white;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-transform: uppercase;
        display: flex;
        align-items: center;

        svg {
          margin-right: 10px;
        }

        label {
          line-height: 28px;
        }
      }

      .contact-info {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: $blue-color;
      }

      form {
        .contact-input {
          margin-bottom: 35px;

          .input-feedback {
            line-height: 20px;
          }
        }

        input {
          height: 60px;
          border: 1px solid $blue-color;
          box-shadow: none;
          margin-bottom: 2px;

          &::placeholder {
            color: $blue-color;
            font-size: 14px;
            font-weight: 400;
          }
        }

        textarea {
          height: 175px;
          margin-bottom: 40px;
          border: 1px solid $blue-color;
          box-shadow: none;

          &::placeholder {
            color: $blue-color;
            font-size: 14px;
            font-weight: 400;
          }
        }

        .submit-btn {
          width: 204px;
          height: 40px;
          font-size: 16px;
          font-weight: 500;

          &::first-letter {
            text-transform: capitalize;
          }

          @include sm {
            width: 129px;
          }
        }
      }

      @include xxxl {
        margin-left: 15px;
      }

      @include lg {
        max-width: 100%;
        margin: 0;
        margin-top: 15px;
      }
    }

    .contact-info {
      // max-width: 500px;
      width: 100%;
      display: inline-block;
      // margin-left: 212px;
      vertical-align: top;

      .cart-total-box {
        margin-top: 0;
        min-height: 237px;
      }

      @include xxxl {
        margin-left: 50px;
        margin-right: 15px;
      }

      @include lg {
        max-width: 100%;
        margin: 0;
      }
    }

    @include lg {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
  }

  @include lg {
    padding-bottom: 0;
  }
}

.cart-title {
  display: flex;
  align-items: center;

  svg {
    margin-right: 15px;
  }

  span {
    line-height: 28px;
  }
}

.contact-detail {
  label {
    font-size: 16px;
    font-weight: 700;
    color: $dark-gray;
    min-width: 46px;
    margin-right: 14px;
  }

  a {
    font-size: 16px;
    font-weight: 400;
    color: $blue-color;

    &.text-underline {
      text-decoration: underline !important;
    }
  }

  p {
    font-size: 16px;
    font-weight: 400;
    color: $blue-color;
    max-width: 163px;
  }

  span {
    margin-top: 6px;
    font-size: 16px;
    font-weight: 400;
    color: $blue-color;
    max-width: 400px;
    display: block;
  }
}

//contact end

//map
.facility-map {
  position: relative;
  padding: 0 15px 150px;
  margin: 12px auto 20px;

  .close-map {
    height: 30px;
    width: 30px;
    background-color: $secondary-text2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $dark-gray;
    position: absolute;
    top: 18px;
    right: 31px;
    border-radius: 2px;
    z-index: 1;

    svg {
      height: 18px;
      width: 18px;
    }
  }

  > div {
    border: 3px solid $secondary-text;

    .gm-style {
      button.gm-control-active {
        right: 49px !important;
      }
    }
  }

  .cabin-main {
    border: 0;

    .cabin-mark {
      position: absolute;
      top: 25%;
      left: 32%;
      color: $white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;

      svg {
        max-height: 75px;
      }

      &.two {
        left: 25%;
      }

      &.three {
        top: 20%;
        left: 29%;
      }

      &.four {
        top: 36%;
        left: 27%;
      }

      &.five {
        top: 36%;
        left: 31%;
      }

      &.six {
        top: 31%;
        left: 41%;
      }

      &.seven {
        top: 29%;
        left: 45%;
      }

      &.eight {
        top: 29%;
        left: 55%;
      }

      &.nine {
        top: 13%;
        left: 60%;
      }

      &.ten {
        top: 15%;
        left: 55%;
      }

      &.eleven {
        top: 29%;
        left: 59%;
      }
    }
  }

  @include lg {
    padding-bottom: 0;
  }
}

//map end
.cart-title {
  background-color: $dark-gray;
  color: $white;
  border-radius: 5px;
  padding: 18px 23px;
  margin-top: 44px;
  margin-bottom: 29px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;

  @include md {
    font-size: 16px;
  }

  @include md {
    font-size: 14px;
  }
}

.cart-sticky {
  position: sticky;
  top: 110px;
  height: max-content;
}

.cart-total-box {
  border: 1px solid $dark-gray;
  border-radius: 5px;
  margin-top: 44px;
  margin-bottom: 40px;

  .cart-title {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-bottom: 17px;
  }

  .cart-detail {
    padding: 0 22px;

    .cart-dates {
      .cart-from {
        display: flex;
        flex-wrap: wrap;

        p {
          color: $blue-color;
          display: flex;
          align-items: center;
          margin-right: 18px;

          &:last-child {
            margin-right: 0;
          }

          span {
            font-size: 16px;
            font-weight: 700;
            margin-right: 18px;

            @include sm {
              font-size: 14px;
            }
          }

          label {
            font-size: 18px;
            font-weight: 400;

            @include sm {
              font-size: 16px;
            }
          }
        }
      }
    }

    .table > :not(:first-child) {
      border: 0;
    }

    th {
      font-size: 16px;
      font-weight: 700;
      color: $blue-color;

      @include sm {
        font-size: 14px;
      }

      &.border-bottom-0 {
        border: 0 !important;
      }
    }

    td {
      font-size: 14px;
      font-weight: 400;
      color: $blue-color;

      @include sm {
        font-size: 12px;
      }

      &.border-bottom-0 {
        border: 0 !important;
      }
    }

    @include sm {
      padding: 0px 10px;
    }
  }
}

//checkout
.checkout-main {
  padding-bottom: 165px;
  min-height: calc(100vh - 370px);
  .facility-list {
    .facility-box {
      .facility-img {
        @include lg {
          min-width: 260px;
        }
      }

      .facility-info {
        width: -webkit-fill-available;
        background-color: $dark-gray;
        position: relative;

        .input-icon {
          min-width: auto;
          position: relative;
        }

        .btn-primary {
          display: none;

          &.close-btn {
            display: block;
            height: 30px;
            width: 30px;
            border-radius: 2px;
            text-align: center;
            padding: 0;
            position: absolute;
            right: 12px;
            top: 12px;

            svg {
              height: 16px;
              width: 16px;
            }
          }
        }

        .facility-price div p {
          color: #fffcc5;
        }
      }

      @include md {
        .facility-dropdowns {
          flex-wrap: wrap;
          justify-content: center;

          .input-icon {
            width: calc(50% - 10px);
          }
        }

        .arrival {
          flex-wrap: wrap;
          min-width: initial;
          gap: 15px;

          .input-icon {
            width: 100%;
            margin: 0;

            .form-control {
              border-radius: 4px;
            }
          }
        }
      }
    }
  }

  .terms-block {
    text-align: center;

    a {
      color: $blue-color;
      text-decoration: underline !important;
      display: block;
      margin-bottom: 19px;
    }

    .form-check {
      display: inline-block;
      margin-right: 30px;
      font-size: 14px;
      font-weight: 400;
      color: $black;
      position: relative;

      input {
        &.form-check-input {
          position: absolute;
          opacity: 0;
          right: 0;
          left: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          margin: 0;

          &:checked {
            + .form-label {
              &::after {
                background-color: $secondary;
                border: 0;
              }
            }
          }
        }
      }

      .form-label {
        &::after {
          content: '';
          height: 14px;
          width: 14px;
          position: absolute;
          border: 1px solid $gray-color;
          left: -4px;
          top: -7px;
          bottom: 0;
          border-radius: 50%;
          margin: auto;
        }

        &::before {
          content: '';
          height: 22px;
          width: 22px;
          position: absolute;
          border: 1px solid $gray-color;
          left: -8px;
          border-radius: 50%;
        }
      }
    }

    .btn {
      text-decoration: none !important;
      display: block;
      font-size: 22px;
      font-weight: 500;
      padding: 18px;
      max-width: 256px;
      margin: auto;
      margin-top: 16px;

      @include md {
        font-size: 18px;
        max-width: 190px;
        padding: 15px;
      }

      @include sm {
        font-size: 16px;
        max-width: 150px;
        padding: 14px 22px;
      }
    }
  }

  @include lg {
    padding-bottom: 40px;
  }
}

//checkout end

//calendar
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: $secondary !important;
  border-color: $secondary !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  background: $secondary !important;
}

.flatpickr-calendar {
  .flatpickr-month,
  .flatpickr-weekdays,
  span.flatpickr-weekday,
  .flatpickr-monthDropdown-months {
    background-color: $secondary !important;

    &:hover {
      background-color: $secondary !important;
    }
  }

  &.arrowTop:after {
    border-bottom-color: $secondary !important;
  }

  .flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
  .flatpickr-months .flatpickr-next-month.flatpickr-next-month,
  .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
    &:hover {
      svg {
        fill: $primary !important;
      }
    }
  }
}

//calendar end

//direction
.document-menu .document-link > div {
  height: 369px !important;

  @include sm {
    height: 320px !important;
  }
}

//direction end

//login
.login-main {
  margin: 72px auto 20px;

  .login {
    margin-left: 50px;

    .login-title {
      margin-bottom: 37px;

      h4 {
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        color: $blue-color;
        font-family: $font-family;
      }

      p {
        font-size: 14px;
        color: $blue-color;
        font-weight: 400;
      }
    }

    form {
      .contact-input {
        margin-bottom: 33px;

        input {
          margin-bottom: 2px;
          max-width: 505px;
          height: 60px;
          border-color: $dark-gray;
          border-radius: 5px;
          box-shadow: none;
          outline: none;
          font-size: 16px;
          font-weight: 400;
          color: $blue-color;

          &::placeholder {
            color: $blue-color;
          }

          @include md {
            height: 42px;
            font-size: 14px;
          }
        }

        .text-danger {
          font-size: 14px;

          @include lg {
            font-size: 12px;
          }
        }

        @include md {
          margin-bottom: 20px;
        }
      }

      .form-label {
        margin-bottom: 23px;
        font-size: 14px;
      }

      .form-check-input[type='checkbox'] {
        border-color: $black;
        box-shadow: none;
        border-radius: 6px;
        height: 20px;
        width: 20px;
        margin-top: 3px;
        margin-right: 17px;

        &:checked {
          background-color: $secondary;
          border-color: $secondary;
        }
      }

      .btn-primary {
        min-width: 204px;
        height: 40px;
        font-size: 16px;
        font-weight: 500;
        display: block;
        margin-bottom: 19px;
      }
    }

    a {
      color: $blue-color;
      font-size: 14px;

      &:hover {
        color: $secondary !important;
      }
    }

    .divider {
      max-width: 505px;
      text-align: center;
      position: relative;
      margin-top: 35px;
      margin-bottom: 42px;
      font-size: 18px;
      font-weight: 700;
      color: $blue-color;

      &::after,
      &::before {
        content: '';
        position: absolute;
        height: 1px;
        background-color: $blue-color;
        top: 0;
        bottom: 0;
        width: calc(50% - 34px);
        margin: auto;
        left: 0;
      }

      &::after {
        right: 0;
        left: auto;
      }
    }

    .btn-outline-secondary {
      max-width: 302px;
      width: 100%;
      height: 40px;
      border-color: $black;
      color: $black;
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      display: block;
      margin-bottom: 25px;
      text-align: left;

      svg {
        margin-right: 34px;
        @include transition5s();
      }

      &:hover {
        background-color: rgba($black, 0.8);
        color: $white;

        svg {
          fill: $white;
        }
      }

      @include sm {
        font-size: 14px;
        max-width: 275px;
      }
    }

    @include lg {
      margin-left: 0;
    }

    @include md {
      margin-bottom: 50px;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $white inset !important;
  font-size: 16px;
}

//login end

//acoount-detail
.account-info {
  padding-bottom: 220px;
  display: inline-flex;
  margin-left: 120px;
  justify-content: space-between;
  //column-gap: 120px;
  flex-wrap: wrap;
  gap: 25px;
  width: calc(100% - 540px);
  margin-top: 44px;

  .account-info-box {
    max-width: 500px;
    height: 281px;
    width: 48%;

    .contact-detail {
      padding: 0px 22px;
      position: relative;

      span,
      a,
      p {
        color: $black;
        font-size: 14px;
      }

      .edit-link {
        position: absolute;
        top: 0;
        right: 18px;
      }
    }

    .cart-total-box {
      min-height: 281px;
      margin: 0;
    }

    @include xl {
      width: 100%;
    }
  }

  .booking-detail {
    max-width: 496px;
    width: 100%;
    overflow: auto;

    .table {
      min-width: 420px;

      &.d-sm-none {
        min-width: initial;
      }

      .table-total {
        font-size: 18px;
      }

      th {
        font-weight: 700;
      }

      th,
      td {
        color: $black;
        border-color: $blue-color;
      }

      td {
        padding: 1.25rem 0.5rem;

        span {
          @include sm {
            display: block;
          }
        }
      }

      tr {
        &:last-child {
          text-align: right;
        }
      }

      &.bookings-table {
        td {
          padding: 1.25rem 0.5rem;
        }
      }
    }

    @include xxll {
      max-width: 66%;
    }

    @include xl {
      max-width: 100%;
    }

    &.bookings-table {
      @include lg {
        max-width: 575px;
        margin: auto;

        tbody {
          width: 100%;

          tr,
          td {
            width: 100%;
          }
        }
      }

      @include sm {
        max-width: 575px;
        margin: auto;

        tbody {
          width: 100%;

          tr,
          td {
            width: auto;
            vertical-align: middle;
          }
        }
      }
    }
  }

  &.booking {
    .account-info-box {
      height: initial;

      .cart-total-box {
        min-height: 135px;
      }

      @include xxll {
        max-width: 46%;
      }

      @include xl {
        max-width: 100%;
      }
    }
  }

  @include lg {
    width: 100%;
    margin: 0;
    padding: 0px 15px;
    margin-bottom: 40px;
    padding-bottom: 0 !important;
    justify-content: center;
  }
}

//acoount-detail end

//address
.address-form {
  padding-bottom: 380px;
  margin-top: 44px;
  width: calc(100% - 540px);
  margin-left: 120px;
  display: inline-block;

  form {
    .contact-input {
      margin-bottom: 37px;

      input,
      select {
        height: 60px;
        max-width: 597px;
        border-color: $gray-color1;
        border-radius: 5px;
        box-shadow: none;
        outline: none;
        font-size: 16px;
        font-weight: 400;
        color: $blue-color;

        &::placeholder {
          color: $blue-color;
        }

        @include lg {
          margin: auto;
        }

        @include md {
          height: 42px;
          font-size: 14px;
        }
      }

      select {
        background-image: url(../images/icons/chevron-down.svg);
      }
    }

    .btn-primary {
      width: 204px;
      height: 40px;
      margin-bottom: 40px;

      @include lg {
        margin: auto;
        display: block;
        margin-bottom: 40px;
      }
    }
  }

  @include xl {
    margin-left: 50px;
    width: calc(100% - 460px);
  }

  @include lg {
    padding-bottom: 0;
    width: auto;
    margin: 0 15px;
  }
  &.reset-form {
    @include lg {
      margin: 0 auto;
      display: block;
      max-width: 448px;
      padding: 0px 15px;
    }
  }
}

//address end

//reset form
.reset-form {
  p {
    max-width: 448px;
    font-size: 16px;
    font-weight: 400;
    color: $blue-color;
    margin-bottom: 20px;
  }
}

//reset form end

// notification toster
body .redux-toastr {
  .toastr {
    .rrt-middle-container {
      padding: 18px 5px;
      .rrt-title {
        margin-bottom: 0;
      }
    }
    .toastr-icon {
      margin-top: 10px !important;
    }
  }
}
// notification toster end

.login-main .login form .contact-input textarea {
  margin-bottom: 2px;
  max-width: 700px;
  height: 100px;
  border-color: #1f3a46;
  border-radius: 5px;
  box-shadow: none;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  color: #172840;
}
#react-select-3-listbox {
  max-height: 200px;
  overflow: auto;
  > div {
    max-height: inherit;
    overflow-x: hidden;
  }
}
.sweet-loading {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  .css-198xogd {
    width: 100px;
    height: 100px;
  }
}
.sweet-loading-wrapper {
  border-radius: 5px;
  padding: 15px 15px 10px 15px;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.footer-images {
  img {
    background-color: #ffffff !important;
    padding: 3px 8px;
    border: 1px solid #ffff;
    border-radius: 3px;
    max-width: 45px;
  }
  :first-child {
    padding: 5px 3px;
  }
}
.css-1s2u09g-control {
  width: 100% !important;
}
.css-1pahdxg-control {
  width: 100% !important;
}

.scheduler {
  min-height: 1010px;
}

.home-testimonial {
  background: #1f3a46;
  font-size: 12px;
  color: #ffffff;
  font-family: 'Poppins';
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  .testimonial-head {
    font-size: 26px;
    font-family: 'Kaushan Script';
    text-align: start;
    color: #fffcc5;
  }
  .testimonial-body {
    text-align: start;
    margin: 5px;
    line-height: 20px;
    letter-spacing: normal;
  }
  .testimonial-read-more {
    color: #e87335;
    text-align: left;
    text-decoration: underline;
    margin: 5px;
    span {
      cursor: pointer;
    }
  }
}

.testimonial-menu {
  // position: absolute;
  top: 0;
  bottom: 0;
  margin: 40px 0 20px;
  width: 100%;
  max-width: 75%;
  max-height: 636px;
  border-radius: 0 $ec-radius $ec-radius 0;
  background-color: rgba($blue-color, 0.8);
  padding-bottom: 20px;

  @include lg {
    margin: 120px 0;
  }

  @include md {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }

  h1 {
    text-transform: capitalize;
    text-align: left;
    margin: 0 auto;
    color: $white;
    font-size: 60px;
    line-height: 70px;
    font-weight: 400;
    padding: 25px 0 15px 175px;

    @include sm {
      font-size: 40px;
      line-height: 60px;
      padding-bottom: 10px;
      padding-left: 10px;
    }
  }

  h3 {
    color: $secondary-text;
    text-align: left;
    font-size: 24px;
    line-height: 35px;
    font-weight: 400;
    padding-left: 175px;
    @include sm {
      font-size: 20px;
      line-height: 25px;
      padding: 0 10px;
    }
  }
  .home-testimonial {
    margin-left: 3rem;
    @include sm {
      margin-left: 0rem !important;
    }
    .testimonial-head {
      font-size: 26px;
      font-family: 'Kaushan Script';
      text-align: start;
      color: #fffcc5;
    }
    .testimonial-body {
      text-align: start;
      margin: 5px;
      line-height: 20px;
      letter-spacing: normal;
    }
  }
}

.is-sticky {
  position: fixed;
  top: 153px;
  z-index: 999;
  transition: all .5s;
  width: 38.666%;

  @include xxxl{
    width: 39.66%;
  }

  @include xl{
    position: static;
    width: initial;
  } 
}



.quick-checkout {
  .checkout-title {
    font-family: $font-family;
    color: #1f3a46;
    margin-top: 14px;
    margin-bottom: 7px;
    text-transform: uppercase;
    font-size: 39px;
    font-weight: 700;
  }
}

.v_line {
  border-left: 2px solid rgb(2, 99, 135);
  position: relative;
  height: 459px;
}
.v_line::before {
  content: 'OR';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  width: 40px;
  padding: 26px;
  /* height: 50%; */
  left: -37px;
  color: black;
  font-size: 18px;
  font-weight: 700;
}

.options-contain {
  .option-title {
    font-family: $font-family;
    color: #1f3a46;

    font-size: 18px;
    font-weight: 700;
  }
}

.checjout-contain {
  max-width: 400px;
}

// new added changes 

@media (max-width: 560px){
  .change-flex-mobile{
    flex-direction: column;
    height: auto !important;
    gap: 0px !important;
  }
      .change-padding-mobile{
        padding-left: 0px !important;
      }
      .mobile-option-or.v_line::before {
        content: 'OR';
        position: absolute;
        top: inherit;
        bottom: -60px;
        transform: translateY(-50%);
        background-color: white;
        width: 40px;
        padding: 26px;
        /* height: 50%; */
        left: inherit;
        right: 40vw;
      }
      .mobile-option-or.v_line {
        border-left: 0px solid #026387;
        height: 50px;
      }
      .quick-checkout .checkout-title {
        padding: 5px;
    }
  }
  @media (max-width: 992px){
    .fullNews-page.mt-3{
      margin-top: 0rem!important;
      .cust-container.mt-5{
        margin-top: 0rem!important;
      }
    }
    .wrapper-home-testimonial-added{
      display: flex;
      flex-direction: column-reverse;
    }
  }
  @media (max-width: 575px){
    .main-div.home-main{
      .search-block.home{
        .title{
          padding-top: 25vh;
        }
      }
    }
    .btns-group.search-btn{
      >a{
        order: 2;
        margin-top: 10px;
      }
    }
  }

  .moneris-checkout-class#monerisCheckout{
    min-height: 100vh !important;
    height: 100vh !important;
    position: relative !important;
  }
  .main-div.home-main{
    overflow: hidden;
  }



  .info-home{
    color: black;
    position: absolute;
   
    bottom: 142px;
    width: 100%;
    background-color: white;
    z-index: 1000;
    padding: 16px;
    height: 75px;
  }

  .info-home-content{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-inline: 10px;
  }
  .info-home-text{
    width: 15%;
    text-align: end;
    font-weight: 600;

  }

  .info-home-text-note{
    width: 85%;
    font-size: 14px;
  }