//@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');


/* kaushan-script-regular - latin */
@font-face {
  font-family: 'Kaushan Script';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/kaushan-script-v14-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/kaushan-script-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/kaushan-script-v14-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/kaushan-script-v14-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/kaushan-script-v14-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/kaushan-script-v14-latin-regular.svg#KaushanScript') format('svg');
  /* Legacy iOS */
}

/* poppins-300 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/poppins-v20-latin-300.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/poppins-v20-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/poppins-v20-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/poppins-v20-latin-300.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/poppins-v20-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/poppins-v20-latin-300.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/poppins-v20-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/poppins-v20-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/poppins-v20-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/poppins-v20-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/poppins-v20-latin-regular.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/poppins-v20-latin-500.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/poppins-v20-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/poppins-v20-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/poppins-v20-latin-500.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/poppins-v20-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/poppins-v20-latin-500.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/poppins-v20-latin-600.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/poppins-v20-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/poppins-v20-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/poppins-v20-latin-600.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/poppins-v20-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/poppins-v20-latin-600.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/poppins-v20-latin-700.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/poppins-v20-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/poppins-v20-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/poppins-v20-latin-700.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/poppins-v20-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/poppins-v20-latin-700.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-800 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/poppins-v20-latin-800.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/poppins-v20-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/poppins-v20-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/poppins-v20-latin-800.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/poppins-v20-latin-800.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/poppins-v20-latin-800.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-900 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/poppins-v20-latin-900.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/poppins-v20-latin-900.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/poppins-v20-latin-900.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/poppins-v20-latin-900.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/poppins-v20-latin-900.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/poppins-v20-latin-900.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* Font Styles|Variable */
$fw200: 200;
$fw300: 300;
$fw400: 400;
$fw500: 500;
$fw600: 600;
$fw700: 700;
$fw800: 800;

.fw200 {
  font-weight: $fw200 !important;
}

.fw300 {
  font-weight: $fw300 !important;
}

.fw400 {
  font-weight: $fw400 !important;
}

.fw500 {
  font-weight: $fw500 !important;
}

.fw600 {
  font-weight: $fw600 !important;
}

.fw700 {
  font-weight: $fw700 !important;
}

.fw800 {
  font-weight: $fw800 !important;
}

//positions
$top: top;
$bottom: bottom;
$left: left;
$right: right;

//fontsize
$ec-h1: 90px;
$ec-h2: 30px;
$ec-h3: 18px;
$ec-h4: 16px;
$ec-h5: 14px;
$ec-h6: 12px;

//various style
$body-font-size: 15px;
$paragraph-font-size: 16px;
$paragraph-lh: 23px;
$ec-radius: 5px;
$ec-radius1: 6px;
$ec-radius2: 8px;
$ec-radius3: 12px;
$ec-radius4: 14px;
$ec-radius5: 20px;



/*Color Variable*/
$primary: #172840;
$secondary: #eb8b3d;
$secondary-text:#e87335;
$secondary-text1:#eeab47;
$secondary-text2:#fc832b;
$white: #ffffff;
$black: #000000;
$blue-color:#172840;
$gray: #707070;
$gray-color:#3f3f41;
$gray-color1:#3f3f3f;
$dark-yellow:#57590c;
$light-yellow:#fffcc5;
$dark-gray:#1f3a46;
$light-gray:#b2b2b2;

$header-font: 'Kaushan Script';
$font-family: 'Poppins',
sans-serif;


// responsive class
// Small tablets and large smartphones (landscape view)

$screen-sm-max: 575px;
// Small tablets (portrait view)
$screen-md-max: 767px;
// Tablets and small desktops
$screen-lg-max: 991px;
// Semi Large tablets and desktops
$screen-xl-max: 1199px;
// Large tablets and desktops
$screen-xxl-max: 1359px;
// Extra Large tablets and desktops
$screen-xxxl-max: 1499px;
// Extra Large tablets and desktops
$screen-xxll-max: 1699px;

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

// Semi Large devices
@mixin lg {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

// large devices
@mixin xl {
  @media (max-width: #{$screen-xl-max}) {
    @content;
  }
}

// Extra large devices
@mixin xxl {
  @media (max-width: #{$screen-xxl-max}) {
    @content;
  }
}

// Double Extra large devices
@mixin xxxl {
  @media (max-width: #{$screen-xxxl-max}) {
    @content;
  }
}

// Double Extra large devices
@mixin xxll {
  @media (max-width: #{$screen-xxll-max}) {
    @content;
  }
}

/*other variavle*/

@mixin transition5s {
  transition: 0.5s ease-in-out !important;
}

@mixin scroll_horizontal {
  &::-webkit-scrollbar-track {
    border-radius: 0.125rem;
    background-color: $white;
  }

  &::-webkit-scrollbar {
    height: 0.375rem;
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.125rem;
    background-color: rgba($primary, 1);
  }
}

@mixin scroll_vertical {
  &::-webkit-scrollbar-track {
    border-radius: 0.125rem;
    background-color: $white;
  }

  &::-webkit-scrollbar {
    width: 0.375rem;
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.125rem;
    background-color: rgba($primary, 1);
  }
}