@import '../../../assets/css/variables';

.search-block {
  max-width: 772px;
  // height: 636px;
  width: 100%;
  background-color: rgba($blue-color, 0.8);
  // position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  margin: 40px 0 20px;
  padding: 24px 40px 42px;
  border-radius: 0px 8px 8px 0;
  z-index: 8;

  @include sm {
    padding: 25px;
    border-radius: 0;
  }
  &.home {
    height: max-content;
    position: absolute;
    margin: auto 0;
    @include xxl {
      padding: 20px 30px;
      top: -20px;
      max-width: 520px;
      .title {
        margin-bottom: 25px;
        h1 {
          font-size: 52px;
          @include md {
            font-size: 48px;
          }
          @include sm {
            font-size: 34px;
          }
        }
        h2 {
          font-size: 26px;
          @include md {
            font-size: 22px;
          }
          @include sm {
            font-size: 18px;
          }
        }
      }
      .input-icon .form-control {
        min-height: 42px;
      }
      .btn-primary.btn-lg {
        height: 50px !important;
        padding: 8px 75px !important;
        @include sm {
          height: 40px !important;
          padding: 6px 35px !important;
          font-size: 1rem !important;
        }
      }
      .arrival-box .input-icon.count .form-select {
        right: 15px;
      }
      .arrival-box .input-icon.count .form-control {
        padding-right: 65px;
      }
    }
    @include lg {
      background-color: rgba($blue-color, 0.8);
      max-width: 100%;
    }
  }
  &.facility-block {
    position: static;
    background-color: $blue-color;
    max-height: initial;
    max-width: 100%;
    padding-top: 61px;
    border-radius: 0 8px 8px 0;
    height: auto;
    margin-top: 0;

    .input-icon {
      @include xxxl {
        .form-control {
          padding-left: 60px;
        }

        svg {
          left: 20px;
        }

        &.without {
          .form-control {
            padding-left: 30px;
          }
        }
      }

      &.count {
        .form-control {
          padding-right: 10px;
        }

        .form-select {
          right: 60px !important;

          @include xxl {
            right: 20px !important;
          }
        }
      }
    }

    .search-btn {
      .btn {
        margin-bottom: 8px;
      }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      padding: 24px 20px;
    }

    @include lg {
      border-radius: 0;
      height: initial;
    }
  }
  @include lg {
    border-radius: 0;
  }
}

.input-icon {
  position: relative;
  margin-bottom: 14px;

  &.without {
    .form-control {
      padding-left: 50px;
    }
  }

  .form-control {
    padding-left: 106px;
    font-size: $ec-h3;
    color: $blue-color;
    min-height: 62px;
    outline: none;
    border: 0;
    box-shadow: none;
    border-radius: 4px;
    background-color: $white !important;
    text-align: left;
    padding-top: 1rem;
    padding-bottom: 1rem;
    > div {
      min-height: 33px;
      border: 0 !important;
      box-shadow: none;
      @include xxl {
        position: relative;
        // top: -10px;
        bottom: 0;
      }
      > div {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        > div {
          &:last-child {
            margin: 0;
          }
        }
        &:last-child {
          > span {
            background-color: transparent;
          }
        }
      }
      svg {
        left: auto;
        right: 0;
      }
    }

    &::placeholder {
      color: $blue-color;
    }

    &:disabled {
      background-color: $white !important;
    }
    .flatpickr-input::after {
      color: $blue-color;
      content: attr(placeholder) !important;
      font-size: 14px;
    }
    .flatpickr-input:focus::after {
      content: '' !important;
    }

    @include md {
      padding-left: 65px;
      padding-top: 13px;
      padding-bottom: 13px;
      max-height: 42px;
      min-height: 42px;
    }

    @include sm {
      padding-left: 65px !important;
      font-size: 16px;
    }
  }

  svg {
    position: absolute;
    left: 40px;
    top: 0;
    bottom: 0;
    margin: auto;

    @include md {
      left: 20px;
      width: 16px;
    }
  }
}

.arrival-box {
  display: flex;

  .input-icon {
    width: 50%;
    margin-right: 1.5px;

    &:first-child {
      .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child {
      margin-right: 0;

      .form-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &.count {
      .form-control {
        padding-right: 148px;

        @include md {
          padding-right: 100px;
        }
      }

      .form-select,
      .person-select {
        position: absolute;
        width: 48px;
        padding: 1px;
        top: 0;
        bottom: 0;
        right: 120px;
        height: 36px;
        background-color: transparent;
        border: 0;
        margin: auto;
        box-shadow: none;
        outline: none;
        font-size: $ec-h3;
        appearance: none;
        color: $blue-color;
        background-image: url(../../../assets/images/icons/chevron-down.svg);
        background-position: calc(100% - 5px) center;
        background-repeat: no-repeat;
        > div {
          border: 0;
          width: 50px;
          box-shadow: none;
          > div {
            padding: 0;
            &:last-child {
              span {
                display: none;
              }
              svg {
                left: auto;
                right: 2px;
              }
            }
          }
        }

        &::-ms-expand {
          display: none;
        }

        @include md {
          right: 50px !important;
        }
      }
      .person-select {
        right: 20%;
      }
      &:last-child {
        .form-select {
          right: 177px;
        }
      }
    }
  }

  @include sm {
    flex-direction: column;

    .input-icon {
      width: 100%;

      .form-control {
        border-radius: 4px !important;
      }
    }
  }
}
